#mobile-navigation-container {
    width: 100%;
}

.mobile-closed-nav, .mobile-open-nav {
    transition-duration: 0.3s;
}

.mobile-sub-nav-li {
    padding-left: 30px;
}

.mobile-closed-nav {
    background: linear-gradient(blue, transparent);
    height: 5em;
}

.mobile-open-nav {
    background: blue;
    height: 100vh;
    z-index: 99;
}

.mobile-link, .mobile-btn {
    font-family: 'Source Code Pro', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 25px;
    padding: 0;
    color: white;
    border: none;
    background-color: transparent;
}

.mobile-btn:hover {
    color: coral;
}

/* Main Nav */
.mobile-main-nav-btn,
.mobile-nav-ul {
    margin: 40px auto 0 auto;
    width: 100%;
}

.mobile-nav-li {
    padding: 10px;
}

/* Animations */
.fade-in {
    animation: fadeIn ease-in 0.3s;
    -webkit-animation: fadeIn ease-in 0.3s;
    -moz-animation: fadeIn ease-in 0.3s;
    -o-animation: fadeIn ease-in 0.3s;
}

.fade-out {
    animation: fadeOut ease-out 0.3s;
    -webkit-animation: fadeOut ease-out 0.3s;
    -moz-animation: fadeOut ease-out 0.3s;
    -o-animation: fadeOut ease-out 0.3s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}