.masthead {
    font-size: 20px;
    font-family: 'Source Code Pro', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    position: fixed;
    top: 0;
    width: 100%;
    height: 5em;
    z-index: 99999;
}

.masthead .btn {
    font-family: 'Source Code Pro', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.masthead a, .masthead a:visited, .masthead .btn {
    color: rgb(255, 255, 255);
}

.masthead a:hover, .masthead .btn:hover {
    color: coral;
}