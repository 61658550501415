h1 {
    width: 100%;
    height: 100%;
    font-size: 10vw;
    text-align: center;
    stroke: white;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;
    color: transparent;
    z-index: 1000;
}